import React from 'react';
import { FormikProps } from 'formik';
import { EnrollmentFormValues } from '../EnrollmentFormValues';
import { SpotSvg } from '@enrollment/spot';
import classNames from 'classnames';

export interface DropdownOptionInterface {
    value: string;
    label: string;
}

export interface FormDropdownFieldProps extends FormikProps<EnrollmentFormValues> {
    id: string;
    label: string;
    placeholder: string;
    options: DropdownOptionInterface[];
}

export const FormDropdownField = ({ id, label, placeholder, values, handleBlur, handleChange, options, errors, touched }: FormDropdownFieldProps) => {
    return (
        <div
            className={classNames('field', {
                'spot-form--error': errors[id] && touched[id],
            })}
        >
            <label htmlFor={id}>{label}</label>
            <div className="spot-form__select">
                <select
                    id={id}
                    className={classNames('spot-form__select-input', {
                        error: errors[id] && touched[id],
                    })}
                    name={id}
                    placeholder={placeholder}
                    value={values[id]}
                    onChange={handleChange}
                    onBlur={handleBlur}
                >
                    {options.map((option) => (
                        <option key={option.value} {...option} className="spot-form__select-option">
                            {option.label}
                        </option>
                    ))}
                </select>
                <div className="spot-form__select-inner"></div>
                <span className="spot-form__select-open-indicator">
                    <SpotSvg icon={'caret-down'} className={'spot-icon spot-form__select-open-icon icon-grey'} />
                </span>
            </div>
            {errors[id] && touched[id] && (
                <span className="spot-form__field-error" id="first-name-error" role="alert">
                    <span className="spot-form__field-error-text">{errors[id]}</span>
                </span>
            )}
        </div>
    );
};
