import React from 'react';
import { FormTitle } from '../../components/FormTitle';
import { FormTextField } from '../../components/fields/FormTextField';
import { Row, Col } from 'react-bootstrap';
import { FormDropdownField } from '../../components/fields/FormDropdownField';
import { FormikProps } from 'formik';
import { EnrollmentFormValues } from '../../components/EnrollmentFormValues';
import { StateOptions, StatesByCountry } from '../../components/helpers/StateOptions';
import { getSupportedPimsOptions } from '../../components/helpers/SupportedPimsOptions';
import { getCountryOptions } from '../../components/helpers/CountryOptions';
import { useTranslation } from 'react-i18next';
import { useStore } from '../../hooks';

export const PracticeStep = (props: FormikProps<EnrollmentFormValues>) => {
    const enrollmentStore = useStore('enrollmentStore');
    const { t } = useTranslation();

    const countryValue = props.values.country;
    const currentStateOptions = StatesByCountry[countryValue] || StateOptions;

    const pimsOptions = getSupportedPimsOptions(t, enrollmentStore.pimsTypes);

    return (
        <>
            <FormTitle text={t('practice:title', 'For which practice are we creating a data connection?')} image={'practice.svg'} />

            <Row>
                <Col xs="12">
                    <FormTextField {...props} id="practiceName" label={t('practice:name', 'Practice Name')} placeholder="" />
                </Col>
            </Row>
            <Row>
                <Col xs="6">
                    <FormDropdownField
                        {...props}
                        id="country"
                        label={t('practice:country', 'Country')}
                        placeholder=""
                        options={getCountryOptions(t)}
                        handleChange={(event) => {
                            props.setValues({
                                ...props.values,
                                country: event.target.value,
                                state: '',
                            });
                        }}
                    />
                </Col>
            </Row>
            <Row>
                <Col xs="12">
                    <FormTextField {...props} id="address1" label={t('practice:address1', 'Address 1')} placeholder="" />
                </Col>
            </Row>
            <Row>
                <Col xs="12">
                    <FormTextField {...props} id="address2" label={t('practice:address2', 'Address 2 (Optional)')} placeholder="" />
                </Col>
            </Row>
            <Row>
                <Col xs="6">
                    <FormTextField {...props} id="city" label={t('practice:city', 'City')} placeholder="" />
                </Col>
                <Col xs="3">
                    <FormDropdownField {...props} id="state" label={t('practice:state', 'State')} placeholder="" options={currentStateOptions} />
                </Col>
                <Col xs="3">
                    <FormTextField {...props} id="postalCode" label={t('practice:postal', 'Postal Code')} placeholder="" />
                </Col>
            </Row>
            <Row>
                <Col xs="6">
                    <FormDropdownField {...props} id="practicePims" label="Current PIMS" placeholder="" options={pimsOptions} />
                </Col>
            </Row>
        </>
    );
};
