import './index.scss';
import 'reflect-metadata'; // DO NOT REMOVE THIS IMPORT
import { BrowserRouter } from 'react-router-dom';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { App } from './App';
import { RootStore } from './store/RootStore';
import { registerPagesStore } from './pages/stores';
import './i18n';
import ioc, { boot_di, StoresContext } from './services/ioc';

boot_di();

setTimeout(() => {
    registerPagesStore();
    const rootStore = ioc.get(RootStore);

    ReactDOM.render(
        <StoresContext.Provider value={rootStore}>
            <BrowserRouter>
                <App />
            </BrowserRouter>
        </StoresContext.Provider>,
        document.getElementById('root') as HTMLElement,
    );
});
