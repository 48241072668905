import React from 'react';
import { StoresContext } from '../services/ioc';
import { DomainStores, RootStore } from '../store/RootStore';

type SelectValue<T, TState> = T extends keyof TState ? TState[T] : RootStore;

export function useStore<T extends keyof DomainStores | undefined = undefined>(key?: T): SelectValue<T, DomainStores> {
    const stores = React.useContext(StoresContext);
    return (key ? stores.pages[key as keyof DomainStores] : stores) as SelectValue<T, DomainStores>;
}
