import { Inject, Service } from 'typedi';
import { BaseHttp } from './http/BaseHttp';
import { CompletedEnrollmentDto, SubmittedEnrollmentDto, SubmittedEnrollmentErrorsDto, SubmittedEnrollmentResponseDto, PimsTypeDto } from './dtos';
import { makeAutoObservable } from 'mobx';

@Service()
export class EnrollmentService {
    constructor(@Inject('http') private readonly http: BaseHttp) {
        makeAutoObservable(this);
    }

    checkForInitiatedEnrollment(programName: string, partnerId: string, enrollmentSlug: string): Promise<CompletedEnrollmentDto> {
        return this.http.get('/API/IDEXX/:programName/:partnerId/CompletedEnrollment/:enrollmentSlug', {
            pathParams: { enrollmentSlug, partnerId, programName },
        });
    }

    submitEnrollment(
        programName: string,
        partnerId: string,
        enrollmentSlug: string,
        data: SubmittedEnrollmentDto,
    ): Promise<SubmittedEnrollmentResponseDto | SubmittedEnrollmentErrorsDto> {
        return this.http.post('/API/IDEXX/:programName/:partnerId/CompletedEnrollment/:enrollmentSlug', data, {
            pathParams: { enrollmentSlug, partnerId, programName },
        });
    }

    getDataPointPimsTypes(): Promise<PimsTypeDto[]> {
        return this.http.get('/API/IDEXX/datapoint-pims');
    }
}
