import React, { Suspense } from 'react';
import styles from './App.module.css';
import { observer } from 'mobx-react';
import { EnrollmentRouter } from './router/EnrollmentRouter';
import routes from './router/routes';
import { ErrorBoundary } from './pages/ErrorBoundary';
import { StoresContext } from './services/ioc';

@observer
export class App extends React.Component {
    static contextType = StoresContext;

    context!: React.ContextType<typeof StoresContext>;

    async componentDidMount(): Promise<void> {
        await this.context.loadUserData();
    }

    render() {
        return (
            <Suspense fallback={null}>
                <div className={styles.enrollmentApp}>
                    <ErrorBoundary>
                        <EnrollmentRouter routes={routes} />
                    </ErrorBoundary>
                </div>
            </Suspense>
        );
    }
}
