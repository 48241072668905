import React, { FC, useEffect, useState } from 'react';
import { Formik, FormikProps } from 'formik';
import { Container } from 'react-bootstrap';
import { PracticeStep } from '../pages/steps/PracticeStep';
import { EnrollmentFormFooter } from './EnrollmentFormFooter';
import * as Yup from 'yup';
import { ContactStep } from '../pages/steps/ContactStep';
import { getContactValidationSchema, getEulaValidationSchema, getPracticeValidationSchema } from '../pages/steps/ValidationSchemas';
import { EulaFooterElements, EulaStep } from '../pages/steps/EulaStep';
import { ErrorFocus } from './ErrorFocus';
import { scrollToTop } from './helpers/ScrollToTop';
import { withTranslation, WithTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import { EnrollmentFormValues } from './EnrollmentFormValues';
import { PrefilledValuesToFormValuesTransformer } from '../services/transformers/PrefilledValuesToFormValues.transformer';
import { Loading } from './Loading';
import { UnknownError } from './UnknownError';
import { ApiErrorType } from '../services/error';
import { useStore } from '../hooks';

export interface EnrollmentFormStateInterface {
    brandedImage: string;
    currentStepIndex: number;
    totalStepCount: number;
    validateOnChange: boolean;
    inError: boolean;
}

interface FormStep {
    step: FC<any>;
    schema: any;
    extraFooterElement?: FC<any>;
}

const EnrollmentForm = observer(function (props: WithTranslation) {
    const enrollmentStore = useStore('enrollmentStore');
    const [initialFormValues, setInitialFormValues] = useState<EnrollmentFormValues>();

    const [state, setState] = useState<EnrollmentFormStateInterface>({
        brandedImage: 'idexx_logo.svg',
        currentStepIndex: 0,
        inError: false,
        totalStepCount: 0,
        validateOnChange: false,
    });
    const [orderedSteps, setOrderedSteps] = useState<FormStep[]>([]);

    const prevStep = (props: FormikProps<EnrollmentFormValues>): void => {
        setState((state: EnrollmentFormStateInterface) => ({
            ...state,
            currentStepIndex: Math.max(state.currentStepIndex - 1, 0),
            validateOnChange: false,
        }));
        props.setTouched({});
        scrollToTop();
    };

    const nextStep = (props: FormikProps<EnrollmentFormValues>): Promise<void> => {
        setState({ ...state, validateOnChange: true });
        return props.submitForm();
    };

    const handleSubmit = async (values: EnrollmentFormValues, formikbag): Promise<void> => {
        const { currentStepIndex, totalStepCount } = state;
        if (currentStepIndex < totalStepCount - 1) {
            // Next Page
            setState((state: EnrollmentFormStateInterface) => ({
                ...state,
                currentStepIndex: state.currentStepIndex + 1,
                validateOnChange: false,
            }));
            formikbag.setTouched({});
            scrollToTop();
        } else {
            // Submit the form
            try {
                const response = await enrollmentStore.submitEnrollment(values);

                // TODO: remove QA log statement, eventually
                console.log('response:', response);
            } catch (error) {
                // TODO: remove QA log statement, eventually
                console.log('Submit error occurred', error);
            }
        }
    };

    useEffect(() => {
        (async () => {
            try {
                const [prefilledData] = (await Promise.all([enrollmentStore.checkEnrollment(), enrollmentStore.loadPimsTypes()])) as any;

                // prefill any form values
                const transformer = new PrefilledValuesToFormValuesTransformer();
                const initialFormValues = {
                    ...transformer.transform(prefilledData),
                    ...enrollmentStore.questions.reduce((agg, question) => {
                        agg[question.questionText] = null;
                        return agg;
                    }, {}),
                };

                const orderedSteps: FormStep[] = [
                    { step: PracticeStep, schema: getPracticeValidationSchema(props.t) },
                    {
                        step: ContactStep,
                        schema: getContactValidationSchema(props.t, enrollmentStore.questions),
                    },
                    { step: EulaStep, schema: getEulaValidationSchema(props.t), extraFooterElement: EulaFooterElements },
                ];

                setInitialFormValues(initialFormValues);
                setOrderedSteps(orderedSteps);
                setState({ ...state, totalStepCount: orderedSteps.length });
            } catch (error) {
                setState({ ...state, inError: true });
            }
        })();
    }, []);

    if (enrollmentStore.error?.type === ApiErrorType.UNKNOWN || state.inError) {
        return <UnknownError />;
    }

    if (enrollmentStore.isLoading || state.totalStepCount === 0) {
        return <Loading text={props.t('common:loading', 'Loading...')} />;
    }

    if (enrollmentStore.isComplete) {
        if (enrollmentStore.ReturnURL) {
            window.location.href = enrollmentStore.ReturnURL.toString();
        }
        return <Loading text={props.t('common:loading', 'Loading...')} />;
    }

    const { currentStepIndex, totalStepCount, brandedImage, validateOnChange } = state;
    const validationSchema: Yup.ObjectSchema = orderedSteps[currentStepIndex].schema;
    const CurrentStep = orderedSteps[currentStepIndex].step;
    const FooterElement = orderedSteps[currentStepIndex].extraFooterElement;
    const apiError = enrollmentStore.error;

    return (
        <Container className="enrollment-app">
            {enrollmentStore.isSubmitting && <Loading asOverlay={true} />}
            {initialFormValues && (
                <Formik
                    initialValues={initialFormValues}
                    onSubmit={handleSubmit}
                    validationSchema={validationSchema}
                    validateOnChange={validateOnChange}
                    validateOnBlur={validateOnChange}
                    enableReinitialize
                >
                    {(props) => {
                        return (
                            <form autoComplete="off" className="enrollment-form">
                                <ErrorFocus {...props} apiError={apiError} />
                                <div className="enrollment-form-content">
                                    <CurrentStep {...props} />
                                </div>
                                <EnrollmentFormFooter
                                    {...props}
                                    totalSteps={totalStepCount}
                                    currentStepIndex={currentStepIndex}
                                    brandedImage={brandedImage}
                                    onNext={nextStep}
                                    onBack={prevStep}
                                >
                                    {FooterElement && <FooterElement {...props} />}
                                </EnrollmentFormFooter>
                            </form>
                        );
                    }}
                </Formik>
            )}
        </Container>
    );
});

export default withTranslation()(EnrollmentForm);
