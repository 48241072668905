import { Service } from 'typedi';
import queryString from 'query-string';
import { makeAutoObservable } from 'mobx';
import { EnrollmentPageStore } from '../pages/EnrollmentPageStore';

export interface DomainStores {
    enrollmentStore: EnrollmentPageStore;
}

@Service()
export class RootStore {
    bootstrapStores = (stores: DomainStores) => {
        this.pages = stores;
    };

    pages: DomainStores;

    authToken: string | null;

    enrollmentSlug: string;

    programName: string;

    partnerId: string;

    constructor() {
        makeAutoObservable(this);
    }

    getAuthToken = (): string | null => {
        return this.authToken;
    };

    // TODO: This will likely be changed in the future; for now, in order to test and debug,
    // we will pass the required data as a url query parameters
    loadUserData = (): void => {
        const queryValues = queryString.parse(window.location.search);
        //in short term, token support removed as part of https://jira.idexx.com/browse/EP-181. Should be protected in EP-191.
        //this.authToken = queryValues['token'] as string;
        this.partnerId = queryValues['partnerId'] as string;
        this.programName = queryValues['programName'] as string;
        this.enrollmentSlug = queryValues['enrollmentSlug'] as string;
    };
}
