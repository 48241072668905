import React from 'react';
import { Route, Switch } from 'react-router-dom';
import BasicLayout from '../layout/basic/BasicLayout';
import { autorun } from 'mobx';

interface ConnectRouterProps {
    routes: any[];
    children?: any;
}

export class EnrollmentRouter extends React.Component<ConnectRouterProps, { prevPath: string; prevToken: any }> {
    state = {
        prevPath: 'm2n3b4m5n2b345',
        prevToken: null,
    };

    componentDidMount() {
        // const { routing } = this.props;
        autorun(() => {
            const { prevPath } = this.state;
            const newState: any = {};
            const currentPath = window.location.pathname;
            if (!(prevPath.includes(currentPath) || currentPath.includes(prevPath)) && currentPath !== prevPath) {
                newState.prevPath = currentPath;
            }
            this.setState(newState);
        });
    }

    render() {
        const { routes } = this.props;
        const routesCmp = routes.map((route, index) => {
            const { Component, config } = route;
            const Layout = route.Layout ? route.Layout : BasicLayout;
            return (
                <Route key={index} path={route.path} exact={route.exact}>
                    <Layout {...config}>
                        <Component {...config} />
                    </Layout>
                </Route>
            );
        });
        return <Switch>{routesCmp}</Switch>;
    }
}
