import * as Yup from 'yup';
import { TFunction } from 'i18next';
import { EnrollmentQuestionDto } from '../../services/dtos';

const requiredMessage = (translate: TFunction) => translate('validation:required', 'Required');
const invalidMessage = (translate: TFunction) => (error: Partial<Yup.TestMessageParams>) =>
    translate('validation:invalidField', { field: error.label || error.path, defaultValue: 'Invalid' });
const maxLengthMessage = (translate: TFunction) => (error: Partial<Yup.TestMessageParams & { max: number }>) =>
    translate('validation:maxLength', { field: error.label || error.path, length: error.max, defaultValue: 'Too long' });
const phoneValidationMessage = (translate: TFunction) => (error: Partial<Yup.TestMessageParams>) =>
    translate('validation:phoneValidation', {
        field: error.label || error.path,
        defaultValue: 'Must consist only of numbers and be 9 or 10 digits long',
    });

/* eslint sort-keys: 0 */
/* Validation order affects scroll-to-error order */

export const getPracticeValidationSchema = (translate: TFunction) => {
    return Yup.object().shape({
        practiceName: Yup.string()
            .label(translate('validation:practiceName', 'Practice Name'))
            .required(requiredMessage(translate))
            .max(80, maxLengthMessage(translate)),
        country: Yup.string().label(translate('validation:country', 'Country')).required(requiredMessage(translate)),
        address1: Yup.string()
            .label(translate('validation:address1', 'Address'))
            .required(requiredMessage(translate))
            .max(50, maxLengthMessage(translate)),
        address2: Yup.string().label(translate('validation:address2', 'Address')).max(30, maxLengthMessage(translate)),
        city: Yup.string().label(translate('validation:city', 'City')).required(requiredMessage(translate)).max(35, maxLengthMessage(translate)),
        state: Yup.string().label(translate('validation:state', 'State')).required(requiredMessage(translate)).max(3, invalidMessage(translate)),
        postalCode: Yup.string()
            .label(translate('validation:postalCode', 'Postal Code'))
            .required(requiredMessage(translate))
            .max(10, maxLengthMessage(translate))
            .when('country', {
                is: 'USA',
                then: Yup.string().matches(/^\d{5}(-{0,1}\d{4})?$/, 'Invalid'),
            })
            .when('country', {
                is: 'CA',
                then: Yup.string().matches(/^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/, 'Invalid'),
            }),
        practicePims: Yup.string()
            .label(translate('validation:value', 'value'))
            .required(requiredMessage(translate))
            .max(50, invalidMessage(translate)),
    });
};

export const getContactValidationSchema = (translate: TFunction, customQuestions: EnrollmentQuestionDto[] = []) => {
    const customQuestionsValidation = customQuestions.reduce((agg, question) => {
        if (question.required) {
            agg[question.questionText] = Yup.string().required(requiredMessage(translate)).nullable().max(30, maxLengthMessage(translate));
        } else {
            agg[question.questionText] = Yup.string().nullable().max(30, maxLengthMessage(translate));
        }
        return agg;
    }, {});

    return Yup.object().shape({
        firstName: Yup.string()
            .label(translate('validation:firstName', 'First Name'))
            .required(requiredMessage(translate))
            .max(80, maxLengthMessage(translate)),
        lastName: Yup.string()
            .label(translate('validation:lastName', 'Last Name'))
            .required(requiredMessage(translate))
            .max(80, maxLengthMessage(translate)),
        contactTitle: Yup.string()
            .label(translate('common:title', 'Title'))
            .required(requiredMessage(translate))
            .max(30, maxLengthMessage(translate)),
        phoneNumber: Yup.string()
            .label(translate('validation:phone', 'Phone Number'))
            .required(requiredMessage(translate))
            .matches(/^[0-9]{9,10}$/, { message: phoneValidationMessage(translate) }),
        email: Yup.string()
            .required(requiredMessage(translate))
            .label(translate('validation:email', 'Email'))
            .email(invalidMessage(translate))
            .max(80, maxLengthMessage(translate))
            .matches(/^([\w.-]+)@([\w-]+)((\.(\w){2,})+)$/, 'Invalid'),
        ...customQuestionsValidation,
    });
};

export const getEulaValidationSchema = (translate: TFunction) => {
    return Yup.object().shape({
        eulaFirstName: Yup.string()
            .label(translate('validation:firstName', 'First Name'))
            .required(requiredMessage(translate))
            .max(80, maxLengthMessage(translate)),
        eulaLastName: Yup.string()
            .label(translate('validation:lastName', 'Last Name'))
            .required(requiredMessage(translate))
            .max(80, maxLengthMessage(translate)),
        eulaTitle: Yup.string()
            .label(translate('validation:title', 'Title'))
            .required(requiredMessage(translate))
            .max(30, maxLengthMessage(translate)),
        eulaEmail: Yup.string()
            .required(requiredMessage(translate))
            .label(translate('validation:email', 'Email'))
            .email(invalidMessage(translate))
            .max(80, maxLengthMessage(translate))
            .matches(/^([\w.-]+)@([\w-]+)((\.(\w){2,})+)$/, 'Invalid'),
        eulaPhone: Yup.string()
            .label(translate('validation:phone', 'Phone Number'))
            .required(requiredMessage(translate))
            .matches(/^[0-9]{9,10}$/, { message: phoneValidationMessage(translate) }),
        eulaIsAuthorized: Yup.boolean().required(requiredMessage(translate)).oneOf([true], requiredMessage(translate)),
        eulaIsAccepted: Yup.boolean().required(requiredMessage(translate)).oneOf([true], requiredMessage(translate)),
    });
};
