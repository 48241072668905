import { Container } from 'typedi';
import * as mobxLogger from 'mobx-logger';
import { environment } from '../environment/environment';
import { BaseHttp } from './http/BaseHttp';
import { RootStore } from '../store/RootStore';
import { createContext } from 'react';

export const StoresContext = createContext<RootStore>({} as RootStore);

if (environment.isDev) {
    mobxLogger.enableLogging({
        action: true,
        compute: true,
        reaction: true,
        transaction: true,
    });
}

export function boot_di() {
    Container.set('http', new BaseHttp(environment.ENROLLMENT_API, Container.get(RootStore).getAuthToken));
}

export const ioc = Container;
export default ioc;
