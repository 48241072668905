import { DropdownOptionInterface } from '../fields/FormDropdownField';
import { TFunction } from 'i18next';
import { PimsTypeDto } from '../../services/dtos';

export const nonDataPointPims: PimsTypeDto[] = [
    { id: 0, display: 'AVImark', value: 'Avimark' },
    { id: 1, display: 'IDEXX Cornerstone', value: 'Cornerstone' },
    { id: 2, display: 'Infinity', value: 'Infinity' },
    { id: 3, display: 'Intravet Classic', value: 'IntravetClassic' },
    { id: 4, display: 'Intravet Sql', value: 'IntravetSQL' },
    { id: 5, display: 'Via', value: 'Via' },
];

export const getSupportedPimsOptions = (translate: TFunction, pimsTypes: PimsTypeDto[]): DropdownOptionInterface[] => [
    { label: translate('common:selectOption', 'Select an option'), value: '' },
    ...pimsTypes
        .map((pimsType) => ({ label: pimsType.display, value: pimsType.value }))
        .sort((a, b) => {
            const lowerA = a.label.toLowerCase();
            const lowerB = b.label.toLowerCase();

            return lowerA < lowerB ? -1 : lowerA > lowerB ? 1 : 0;
        }),
];
