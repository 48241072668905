import React from 'react';
import { FormTitle } from '../../components/FormTitle';
import { Row, Col } from 'react-bootstrap';
import { FormTextField } from '../../components/fields/FormTextField';
import { FormSingleCheckboxField } from '../../components/fields/FormSingleCheckboxField';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import { ApiErrorType, SubmitBadRequestApiError } from '../../services/error';
import { useStore } from '../../hooks';
import { FormikProps } from 'formik';
import { EnrollmentFormValues } from '../../components/EnrollmentFormValues';

export const EulaStep = observer(() => {
    const enrollmentPage = useStore('enrollmentStore');
    const { t } = useTranslation();
    const submitError = enrollmentPage.error?.type === ApiErrorType.SUBMIT;
    const submitBadRequestError = enrollmentPage.error?.type === ApiErrorType.SUBMIT_BADREQUEST;
    const badRequestError = submitBadRequestError ? (enrollmentPage.error as SubmitBadRequestApiError) : null;

    return (
        <>
            <FormTitle text={t('eula:title', 'Please review the IDEXX DataPoint Terms of Service')} />
            {submitError && (
                <span className="spot-form__field-error" id="apiError-error" role="alert">
                    <span className="spot-form__field-error-text">
                        {t('common:submitError', 'An error occurred while submitting this form, please try again.')}
                    </span>
                </span>
            )}
            {badRequestError && (
                <span className="spot-form__field-error" id="apiError-error" role="alert">
                    <span className="spot-form__field-error-text">
                        {t('common:submitInvalidError', 'The form is invalid. Please correct the issues and try again.')}
                        {Object.keys(badRequestError.errors).map((error) => (
                            <div key={error}>
                                {error} - {badRequestError.errors[error]}
                            </div>
                        ))}
                    </span>
                </span>
            )}
            <Col xs={12}>
                <div className={'eula-text'}>{enrollmentPage.rawEula.content}</div>
            </Col>
        </>
    );
});

export const EulaFooterElements = (props: FormikProps<EnrollmentFormValues>) => {
    const { t } = useTranslation();
    return (
        <>
            <Row>
                <Col xs="4">
                    <FormTextField {...props} id="eulaFirstName" label={t('eula:firstName', 'First Name')} placeholder="" />
                </Col>
                <Col xs="4">
                    <FormTextField {...props} id="eulaLastName" label={t('eula:lastName', 'Last Name')} placeholder="" />
                </Col>
                <Col xs="4">
                    <FormTextField {...props} id="eulaTitle" label={t('eula:position', 'Position/Title')} placeholder="" />
                </Col>
            </Row>
            <Row>
                <Col xs="4">
                    <FormTextField {...props} id="eulaPhone" label={t('eula:phone', 'Phone Number')} placeholder="" />
                </Col>
                <Col xs="8">
                    <FormTextField {...props} id="eulaEmail" label={t('eula:email', 'Email Address')} placeholder="" />
                </Col>
            </Row>
            <Row>
                <Col xs="12">
                    <FormSingleCheckboxField
                        {...props}
                        id="eulaIsAuthorized"
                        label={t('eula:authorized', 'I confirm that I am authorized to make business decisions on behalf of my practice')}
                        small={true}
                    />
                </Col>
            </Row>
            <Row>
                <Col xs="12">
                    <FormSingleCheckboxField
                        {...props}
                        id="eulaIsAccepted"
                        label={t('eula:accepted', 'I have read and accept the terms of conditions of the ISC/DataPoint End User License Agreement')}
                        small={true}
                    />
                </Col>
            </Row>
        </>
    );
};
