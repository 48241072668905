import { EnrollmentPageStore } from './EnrollmentPageStore';
import ioc from '../services/ioc';
import { RootStore } from '../store/RootStore';

export function registerPagesStore() {
    const rootStore = ioc.get(RootStore);

    rootStore.bootstrapStores({
        enrollmentStore: ioc.get(EnrollmentPageStore),
    });
}
