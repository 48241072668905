export class HttpException extends Error {
    constructor(
        public status: string,
        message: string,
        readonly innerException?: any,
    ) {
        super(message);
        // Set the prototype explicitly.
        Object.setPrototypeOf(this, HttpException.prototype);
    }
}
